import React, { useEffect, useState } from "react";
import AuthenticatedTenant from "../Layouts/AuthenticatedLayoutTenant";


function Services(props) {
    const [header, setHeader] = useState("");
    const [user, setUser] = useState("");


    useEffect(() => {
        //inizioalize first view first step
        if (localStorage.getItem("configuration")) {
            const config = JSON.parse(localStorage.getItem("configuration"));
            setHeader(config.data.current);
        }
        if (localStorage.getItem("user")) {
            const user = JSON.parse(localStorage.getItem("user"));
            setUser(user);
        }
    }, []);

    return (
        <AuthenticatedTenant auth={user} errors={props.errors} header={header}>
            <br></br>
            <main>
                <div className="container" id="main-container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-10">


                        </div>
                    </div>
                    <div className="container">
                        <div className="row justify-content-center ">
                            <div className="col-12 col-lg-10">
                                <div className="cmp-hero">
                                    <section className="it-hero-wrapper bg-white align-items-start">
                                        <div className="it-hero-text-wrapper pt-0 ps-0 pb-4 pb-lg-60">
                                            <h1 className="text-black hero-title">
                                                Servizi                        </h1>
                                            <div className="hero-text">
                                                <p>Tutti i servizi comunali per i cittadini, disponibili online o a sportello, per richiedere documenti e permessi, iscriversi a graduatorie ed effettuare pagamenti.</p>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-grey-card">
                        <form role="search" id="search-form" method="get" className="search-form" data-dashlane-rid="44053e9f058ae380" data-form-type="">
                            <button type="submit" className="d-none"></button>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <h2 className="title-xxlarge mb-4 mt-5 mb-lg-10">
                                            Esplora tutti i servizi
                                        </h2>
                                    </div>
                                    <div className="col-12 col-lg-8 pt-lg-50 pb-lg-50">


                                        <div className="cmp-input-search">
                                            <div className="form-group autocomplete-wrapper mb-2 mb-lg-4">
                                                <div className="input-group">
                                                    <label htmlFor="autocomplete-two" className="visually-hidden">Cerca una parola chiave</label>
                                                    <input type="search" className="autocomplete form-control" placeholder="Cerca una parola chiave" id="autocomplete-two" name="search" value="" data-bs-autocomplete="[]" data-dashlane-rid="d2efa501e86efdc0" data-form-type="" />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-primary" type="submit" id="button-3" data-dashlane-label="true" data-dashlane-rid="1d206b544627870f" data-form-type="">
                                                            Invio
                                                        </button>
                                                    </div>
                                                    <span className="autocomplete-icon" aria-hidden="true"><svg className="icon icon-sm icon-primary" role="img" aria-labelledby="autocomplete-label">
                                                        <use href="#it-search"></use></svg></span>
                                                </div>
                                            </div>
                                            <p id="autocomplete-label" className="mb-4">
                                                <strong>6 </strong>servizi trovati in ordine alfabetico
                                            </p>
                                        </div>
                                        <div id="load-more">
                                            <div className="cmp-card-latest-messages mb-3 mb-30" data-bs-target="#">
                                                <div className="card shadow-sm px-4 pt-4 pb-4 rounded">
                                                    <span className="visually-hidden">Categoria:</span>
                                                    <div className="card-header border-0 p-0">
                                                        <a className="text-decoration-none title-xsmall-bold mb-2 category text-uppercase" href="https://stellanello.nemea.cloud/servizi-categoria/tributi-finanze-e-contravvenzioni/">Tributi, finanze e contravvenzioni</a>                </div>
                                                    <div className="card-body p-0 my-2">
                                                        <h3 className="green-title-big t-primary mb-8">
                                                            <a className="text-decoration-none" href="/pagamento-imu" data-element="service-link">Pagare tributi IMU</a>
                                                        </h3>
                                                        <p className="text-paragraph">
                                                            Descrizione breve TEST                </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cmp-card-latest-messages mb-3 mb-30" data-bs-target="#">
                                                <div className="card shadow-sm px-4 pt-4 pb-4 rounded">
                                                    <span className="visually-hidden">Categoria:</span>
                                                    <div className="card-header border-0 p-0">
                                                        <a className="text-decoration-none title-xsmall-bold mb-2 category text-uppercase" href="https://stellanello.nemea.cloud/servizi-categoria/anagrafe-e-stato-civile/">Anagrafe e stato civile</a> - <a className="text-decoration-none title-xsmall-bold mb-2 category text-uppercase" href="https://stellanello.nemea.cloud/servizi-categoria/autorizzazioni/">Autorizzazioni</a> - <a className="text-decoration-none title-xsmall-bold mb-2 category text-uppercase" href="https://stellanello.nemea.cloud/servizi-categoria/catasto-e-urbanistica/">Catasto e urbanistica</a> - <a className="text-decoration-none title-xsmall-bold mb-2 category text-uppercase" href="https://stellanello.nemea.cloud/servizi-categoria/imprese-e-commercio/">Imprese e commercio</a> - <a className="text-decoration-none title-xsmall-bold mb-2 category text-uppercase" href="https://stellanello.nemea.cloud/servizi-categoria/salute-benessere-e-assistenza/">Salute, benessere e assistenza</a> - <a className="text-decoration-none title-xsmall-bold mb-2 category text-uppercase" href="https://stellanello.nemea.cloud/servizi-categoria/tributi-finanze-e-contravvenzioni/">Tributi, finanze e contravvenzioni</a>                </div>
                                                    <div className="card-body p-0 my-2">
                                                        <h3 className="green-title-big t-primary mb-8">
                                                            <a className="text-decoration-none" href="/prenota-appuntamento" data-element="service-link">Prenotazione appuntamento</a>
                                                        </h3>
                                                        <p className="text-paragraph">
                                                            Richiedi un appuntamento presso gli sportelli degli uffici del Comune per usufruire di vari servizi.                </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cmp-card-latest-messages mb-3 mb-30" data-bs-target="#">
                                                <div className="card shadow-sm px-4 pt-4 pb-4 rounded">
                                                    <span className="visually-hidden">Categoria:</span>
                                                    <div className="card-header border-0 p-0">
                                                        <a className="text-decoration-none title-xsmall-bold mb-2 category text-uppercase" href="https://stellanello.nemea.cloud/servizi-categoria/educazione-e-formazione/">Educazione e formazione</a>                </div>
                                                    <div className="card-body p-0 my-2">
                                                        <h3 className="green-title-big t-primary mb-8">
                                                            <a className="text-decoration-none" href="/iscrizione-scuola-infanzia" data-element="service-link">Richiedere iscrizione al trasporto scolastico</a>
                                                        </h3>
                                                        <p className="text-paragraph">
                                                            Descrizione breve TEST                </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="d-flex justify-content-center mt-4" id="load-more-btn">
                                            <button type="button" className="btn btn-outline-primary pt-15 pb-15 pl-90 pr-90 mb-30 mb-lg-50 full-mb text-button" onclick="handleOnClick(`?post_count=3&amp;load_posts=3&amp;search=&amp;post_types=&quot;servizio&quot;&amp;load_card_type=servizio&amp;query_params=[]&amp;additional_filter=null`)" data-element="load-other-cards" data-dashlane-label="true" data-dashlane-rid="5c2d1b43a9420231" data-form-type="">

                                                <span className="">Carica altri risultati</span>
                                            </button>
                                        </div>
                                        <p className="text-center text-paragraph-regular-medium mt-4 mb-0 d-none" id="no-more-results">
                                            Nessun altro risultato</p>

                                        <nav className="pagination-wrapper" aria-label="Navigazione della pagina">
                                        </nav>
                                    </div>
                                    <div className="col-12 col-lg-4 pt-30 pt-lg-5 ps-lg-5 order-first order-md-last">
                                        <div className="link-list-wrap">
                                            <h2 className="title-xsmall-semi-bold">
                                                <span>SERVIZI IN EVIDENZA</span>
                                            </h2>
                                            <ul className="link-list t-primary">
                                                <li className="mb-3 mt-3">
                                                    <a className="list-item ps-0 title-medium underline" href="/pagamento-imu">
                                                        <span>Pagare tributi IMU</span>
                                                    </a>
                                                </li>
                                                <li className="mb-3 mt-3">
                                                    <a className="list-item ps-0 title-medium underline" href="/trasporto-scolastico">
                                                        <span>Richiedere iscrizione al trasporto scolastico</span>
                                                    </a>
                                                </li>
                                                <li className="mb-3 mt-3">
                                                    <a className="list-item ps-0 title-medium underline" href="iscrizione-scuola-infanzia">
                                                        <span>Richiedere iscrizione alla scuola dell’infanzia</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="container py-5">
                        <h2 className="title-xxlarge mb-4">Esplora per categoria</h2>
                        IN SVILUPPO
                    </div>
                    <div className="bg-primary">
                        <div className="container">
                            <div className="row d-flex justify-content-center bg-primary">
                                <div className="col-12 col-lg-6 p-lg-0 px-3">
                                    <div className="cmp-rating pt-lg-80 pb-lg-80" id="rating">
                                        <div className="card shadow card-wrapper" data-element="feedback">
                                            <div className="cmp-rating__card-first">
                                                <div className="card-header border-0">
                                                    <h2 className="title-medium-2-semi-bold mb-0" data-element="feedback-title">
                                                        Quanto sono chiare le informazioni su questa pagina?
                                                    </h2>
                                                </div>
                                                <div className="card-body">
                                                    <fieldset className="rating">
                                                        <input type="radio" id="star5a" name="ratingA" value="5" />
                                                        <label className="full rating-star" htmlFor="star5a" data-element="feedback-rate-5">
                                                            <svg className="icon icon-sm" role="img" aria-labelledby="5-star">
                                                                <use href="#it-star-full"></use>
                                                            </svg>
                                                            <span className="visually-hidden" id="5-star">Valuta 5 stelle su 5</span>
                                                        </label>
                                                        <input type="radio" id="star4a" name="ratingA" value="4" />
                                                        <label className="full rating-star" htmlFor="star4a" data-element="feedback-rate-4">
                                                            <svg className="icon icon-sm" role="img" aria-labelledby="4-star">
                                                                <use href="#it-star-full"></use>
                                                            </svg>
                                                            <span className="visually-hidden" id="4-star">Valuta 4 stelle su 5</span>
                                                        </label>
                                                        <input type="radio" id="star3a" name="ratingA" value="3" />
                                                        <label className="full rating-star" htmlFor="star3a" data-element="feedback-rate-3">
                                                            <svg className="icon icon-sm" role="img" aria-labelledby="3-star">
                                                                <use href="#it-star-full"></use>
                                                            </svg>
                                                            <span className="visually-hidden" id="3-star">Valuta 3 stelle su 5</span>
                                                        </label>
                                                        <input type="radio" id="star2a" name="ratingA" value="2" />
                                                        <label className="full rating-star" htmlFor="star2a" data-element="feedback-rate-2">
                                                            <svg className="icon icon-sm" role="img" aria-labelledby="2-star">
                                                                <use href="#it-star-full"></use>
                                                            </svg>
                                                            <span className="visually-hidden" id="2-star">Valuta 2 stelle su 5</span>
                                                        </label>
                                                        <input type="radio" id="star1a" name="ratingA" value="1" />
                                                        <label className="full rating-star" htmlFor="star1a" data-element="feedback-rate-1">
                                                            <svg className="icon icon-sm" role="img" aria-labelledby="1-star">
                                                                <use href="#it-star-full"></use>
                                                            </svg>
                                                            <span className="visually-hidden" id="1-star">Valuta 1 stelle su 5</span>
                                                        </label>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div className="cmp-rating__card-second d-none" data-step="3">
                                                <div className="card-header border-0">
                                                    <h2 className="title-medium-2-bold mb-0" id="rating-feedback">
                                                        Grazie, il tuo parere ci aiuterà a migliorare il
                                                        servizio!
                                                    </h2>
                                                </div>
                                            </div>
                                            <div className="form-rating d-none">
                                                <div className="d-none rating-shadow" data-step="1">
                                                    <div className="cmp-steps-rating">
                                                        <fieldset className="fieldset-rating-one d-none" data-element="feedback-rating-positive">
                                                            <legend className="iscrizioni-header w-100">
                                                                <h3 className="step-title d-flex align-items-center justify-content-between drop-shadow">
                                                                    <span className="d-block d-lg-inline" data-element="feedback-rating-question">Quali sono stati gli aspetti che hai preferito? </span><span className="step">1/2</span>
                                                                </h3>
                                                            </legend>
                                                            <div className="cmp-steps-rating__body">
                                                                <div className="cmp-radio-list">
                                                                    <div className="card-teaser shadow-rating">
                                                                        <div className="card-body">
                                                                            <div className="form-check m-0">
                                                                                <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                                                                    <input name="rating1" type="radio" id="radio-1" />
                                                                                    <label htmlFor="radio-1" data-element="feedback-rating-answer">Le indicazioni erano chiare</label>
                                                                                </div>
                                                                                <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                                                                    <input name="rating1" type="radio" id="radio-2" />
                                                                                    <label htmlFor="radio-2" data-element="feedback-rating-answer">Le indicazioni erano complete</label>
                                                                                </div>
                                                                                <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                                                                    <input name="rating1" type="radio" id="radio-3" />
                                                                                    <label htmlFor="radio-3" data-element="feedback-rating-answer">Capivo sempre che stavo procedendo correttamente</label>
                                                                                </div>
                                                                                <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                                                                    <input name="rating1" type="radio" id="radio-4" />
                                                                                    <label htmlFor="radio-4" data-element="feedback-rating-answer">Non ho avuto problemi tecnici</label>
                                                                                </div>
                                                                                <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                                                                    <input name="rating1" type="radio" id="radio-5" />
                                                                                    <label htmlFor="radio-5" data-element="feedback-rating-answer">
                                                                                        Altro
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                        <fieldset className="fieldset-rating-two d-none" data-element="feedback-rating-negative">
                                                            <legend className="iscrizioni-header w-100">
                                                                <h3 className="step-title d-flex align-items-center justify-content-between drop-shadow">
                                                                    <span className="d-block d-lg-inline" data-element="feedback-rating-question">Dove hai incontrato le maggiori difficoltà?</span><span className="step">1/2</span>
                                                                </h3>
                                                            </legend>
                                                            <div className="cmp-steps-rating__body">
                                                                <div className="cmp-radio-list">
                                                                    <div className="card-teaser shadow-rating">
                                                                        <div className="card-body">
                                                                            <div className="form-check m-0">
                                                                                <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                                                                    <input name="rating2" type="radio" id="radio-6" />
                                                                                    <label htmlFor="radio-6" data-element="feedback-rating-answer">A volte le indicazioni non erano chiare</label>
                                                                                </div>
                                                                                <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                                                                    <input name="rating2" type="radio" id="radio-7" />
                                                                                    <label htmlFor="radio-7" data-element="feedback-rating-answer">A volte le indicazioni non erano complete</label>
                                                                                </div>
                                                                                <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                                                                    <input name="rating2" type="radio" id="radio-8" />
                                                                                    <label htmlFor="radio-8" data-element="feedback-rating-answer">A volte non capivo se stavo procedendo correttamente</label>
                                                                                </div>
                                                                                <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                                                                    <input name="rating2" type="radio" id="radio-9" />
                                                                                    <label htmlFor="radio-9" data-element="feedback-rating-answer">Ho avuto problemi tecnici</label>
                                                                                </div>
                                                                                <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                                                                    <input name="rating2" type="radio" id="radio-10" />
                                                                                    <label htmlFor="radio-10" data-element="feedback-rating-answer">Altro</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                                <div className="d-none" data-step="2">
                                                    <div className="cmp-steps-rating">
                                                        <fieldset>
                                                            <legend className="iscrizioni-header w-100">
                                                                <h3 className="step-title d-flex align-items-center justify-content-between drop-shadow mb-4">
                                                                    <span className="d-block d-lg-inline">Vuoi aggiungere altri dettagli? </span><span className="step">2/2</span>
                                                                </h3>
                                                            </legend>
                                                            <div className="cmp-steps-rating__body">
                                                                <div className="form-group shadow-rating">
                                                                    <label htmlFor="formGroupExampleInputWithHelp">Dettaglio</label>
                                                                    <input className="form-control" id="formGroupExampleInputWithHelp" aria-describedby="formGroupExampleInputWithHelpDescription" maxlength="200" type="text" data-element="feedback-input-text" />
                                                                    <small id="formGroupExampleInputWithHelpDescription" className="form-text">Inserire massimo 200 caratteri</small>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-nowrap pt-4 w-100 justify-content-center">
                                                    <button className="btn btn-outline-primary fw-bold me-4 btn-back" type="button">
                                                        Indietro
                                                    </button>
                                                    <button className="btn btn-primary fw-bold btn-next" type="submit" form="rating">
                                                        Avanti
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-grey-card">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-6 offset-lg-3 py-5">
                                    <div className="cmp-contacts">
                                        <div className="card w-100">
                                            <div className="card-body">
                                                <h2 className="title-medium-2-semi-bold">Contatta il comune</h2>
                                                <ul className="contact-list p-0">
                                                    <li>
                                                        <a className="list-item" href="https://stellanello.nemea.cloud/domande-frequenti/"><svg className="icon icon-primary icon-sm" aria-hidden="true">
                                                            <use href="#it-help-circle"></use></svg><span>Leggi le domande frequenti</span></a>
                                                    </li>
                                                    <li>
                                                        <a className="list-item" href="https://stellanello.nemea.cloud/servizi/assistenza/" data-element="contacts"><svg className="icon icon-primary icon-sm" aria-hidden="true">
                                                            <use href="#it-mail"></use></svg><span>Richiedi assistenza</span></a>
                                                    </li>
                                                    <li>
                                                        <a className="list-item" href="tel:+39 0182 668000">
                                                            <svg className="icon icon-primary icon-sm" aria-hidden="true">
                                                                <use href="#it-hearing"></use></svg><span>Numero verde +39 0182 668000</span></a>
                                                    </li>
                                                    <li>
                                                        <a className="list-item" href="https://stellanello.nemea.cloud/servizi/prenotazioni/" data-element="appointment-booking">
                                                            <svg className="icon icon-primary icon-sm" aria-hidden="true">
                                                                <use href="#it-calendar"></use>
                                                            </svg><span>Prenota appuntamento</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <h2 className="title-medium-2-semi-bold mt-4">
                                                    Problemi in città
                                                </h2>
                                                <ul className="contact-list p-0">
                                                    <li>
                                                        <a className="list-item" href="#"><svg className="icon icon-primary icon-sm" aria-hidden="true">
                                                            <use href="#it-map-marker-circle"></use></svg><span>Segnala disservizio</span></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </AuthenticatedTenant>
    )
}

export default Services;











