import React, { useEffect, useState } from "react";
import SideList from "../../UI-component/SideList";
import SelectionFormBox from "../../UI-component/SelectionFormBox";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import FormBoxField from "../../UI-component/ui-el/FormBoxField";
import { useSelector, useDispatch } from "react-redux";
import { OfficeAPI } from "../../Apis/OfficeAPI";
import ErrorAlert from "../../UI-component/ErrorAlert";
import stepSlice from "../../context/StepsContext";
import NextBtnForm from "../../UI-component/NexBtnForm";
import Loader from "../../UI-component/Loader";
import SuccessfullPopup from "../../UI-component/SuccessfullPopup";

//OFFICE SELECTOR
function Second(props) {
    const dispatcher = useDispatch();
    const totalCtx = useSelector(state => state.stepSlice.data);

    const ctx = useSelector(state => state.stepSlice.data.ufficio);

    const [officeDetails, setOfficeDetails] = useState(ctx.ufficio);
    const [appointmentDetails, setAppointmentDetails] = useState(ctx?.appuntamenti_disponibili);
    const [availability, setAvailability] = useState(ctx?.disponibilita);
    const [appointmentChoice, setAppointmentChoice] = useState(ctx?.disponibilita);
    const [error, setError] = useState(true);
    const [loading, setLoading] = useState(false);
    const [nonAppDates, setNonAppDates] = useState([]);


    const update = (data) => {
        if (data.id === "appuntamenti_disponibili") {
            setAppointmentDetails({
                id: data.selectionID,
                value: data.data
            })
        } else if (data.id === "disponibilita") {
            setAppointmentChoice({
                id: data.selectionID,
                value: data.data
            });
        }
    }

    const onNext = () => {
        dispatcher(stepSlice.actions.dynamicSave({ id: "Ufficio", internalId: "appuntamenti_disponibili", data: appointmentDetails }));
        dispatcher(stepSlice.actions.dynamicSave({ id: "Ufficio", internalId: "disponibilita", data: appointmentChoice }));

        props.next();
    }

    const save = async () => {
        const data = {
            ufficio: ctx.ufficio,
            appuntamenti_disponibili: appointmentDetails,
            disponibilita: appointmentChoice
        }

        setLoading(true);

        await props.save(data);

        dispatcher(stepSlice.actions.dynamicSave({ id: "Ufficio", internalId: "ufficio", data: ctx.ufficio }));
        dispatcher(stepSlice.actions.dynamicSave({ id: "Ufficio", internalId: "appuntamenti_disponibili", data: appointmentDetails }));
        dispatcher(stepSlice.actions.dynamicSave({ id: "Ufficio", internalId: "disponibilita", data: appointmentChoice }));

        setLoading(false);
    }

    const getOfficeDetails = async () => {
        try {
            setLoading(true);
            const res = await OfficeAPI.getOfficesById(ctx.ufficio.id);

            if (!res.success) {
                return;
            }

            setOfficeDetails(res.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    const getAppointmentDetails = async () => {

        if (!officeDetails?.id || !appointmentDetails?.id) return;

        try {
            setLoading(true);
            const res = await OfficeAPI.getAppointments(officeDetails.id, appointmentDetails.id);

            if (!res.success)
                return;

            let items = [];

            // console.log("res data", res.data);

            for (let [x, y] of res.data.entries()){
                if(y?.startDate) items.push({ id: x, value: y.startDate })
            }

            setAvailability(items);
            setLoading(false)
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    const getNonAppDates = async () => {
        try {
            const res = await OfficeAPI.dateNotAvailableChecker();

            setNonAppDates(res.data);

        } catch (err) {
            console.log(err);
        }

    }

    const calculateDate = () => {
        const dates = [];
        const municipality = JSON.parse(localStorage.getItem("configuration")).data?.current?.description.toLowerCase().includes("nasino")
            || JSON.parse(localStorage.getItem("configuration")).data?.current?.description.toLowerCase().includes("brugnato");


        for (let i = 0; i < 14; i++) {
            const date = new Date()

            date.setDate((date.getDate() + (municipality ? 7 : 1)) + i);
            const itm = date.toLocaleDateString("IT-it", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })

            if (itm.includes("domenica"))
                continue;

            var start = new Date(date.getFullYear(), 0, 0);
            var diff = (date - start) + ((start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000);
            var oneDay = 1000 * 60 * 60 * 24;
            var day = Math.floor(diff / oneDay);

            if (Array.isArray(nonAppDates)) {
                if (!nonAppDates.includes(day))
                    dates.push({ value: date.toLocaleDateString("IT-it", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }), id: day })
            }
        }

        return dates;
    }

    const errorChecker = () => {
        if (appointmentChoice?.id)
            setError(false);
        else
            setError(true);
    }

    useEffect(() => {
        getOfficeDetails();
        getNonAppDates();
        errorChecker();
    }, []);

    useEffect(() => {
        getAppointmentDetails();
    }, [appointmentDetails]);

    useEffect(() => {
        errorChecker();
    }, [appointmentChoice])
    
    // console.log("props.info.disp",props.info.disp,availability)

    return (
        <React.Fragment>
            <Loader loading={loading} />
            <SideList
                infoList={[{ element: "Appuntamenti disponibili", id: "#appuntamenti-disponibili" }, { element: "Ufficio", id: "#office" }]} />
            <div className="col-12 col-lg-8 offset-lg-1 section-wrapper">
                <section>
                    {
                        <ErrorAlert errors={[{ name: "Appuntamenti disponibili", id: "#isee-info" }]} err={error} />
                    }

                    <SelectionFormBox
                        firstSelection={"Seleziona un giorno"}
                        showTitle={"Appuntamenti disponibili*"}
                        title="Appuntamenti Disponibili"
                        description="Seleziona una data per vedere gli orari disponibili"
                        selectTitle="Seleziona l'ufficio"
                        choices={calculateDate()}
                        defaultValue={appointmentDetails?.value ?? "Seleziona un'opzione"}
                        oKey={"Ufficio"}
                        objectify={true}
                        update={update}>
                        {
                            availability &&
                            <>
                                {
                                    availability[0]?.value.startsWith("Non") ?
                                        <p style={{ fontSize: "16px" }}>{availability[0]?.value}</p> :
                                        <>
                                            <SelectionFormBox
                                                disp={props.info.disp}
                                                firstSelection={availability[0]?.value.startsWith("Non") ? availability[0].value : "Seleziona un orario"}
                                                showTitle={""}
                                                title="disponibilita"
                                                description=""
                                                selectTitle="Seleziona l'ufficio"
                                                choices={availability}
                                                defaultValue={appointmentChoice?.value ?? "Seleziona un'opzione"}
                                                oKey={"Ufficio"}
                                                objectify={true}
                                                update={update} />
                                        </>
                                }
                            </>
                        }

                    </SelectionFormBox>

                    <SummaryCard
                        mod={false}
                        cardTitle={officeDetails.title}
                        type="Ufficio">
                        <FormBoxField
                            value={officeDetails.address ?? "Non Compilato"}
                            title={"Indirizzo"} />
                        <FormBoxField
                            value={officeDetails.timetables ?? 'Non Compilato'}
                            title={"Apertura"} />
                    </SummaryCard>
                </section>

                <NextBtnForm
                    saveRequest={save}
                    next={onNext}
                    back={props.back}
                    noSave={false}
                    disabled={error} />
                {
                    props.saved &&
                    <SuccessfullPopup />
                }

                {
                    props.fail &&
                    <SuccessfullPopup isError={true} />
                }
            </div>
        </React.Fragment>
    )
}

export default Second;
