import { ServiceRequestAPI } from "../Apis/ServiceRequestAPI";
import HelpBox from "./HelpBopx";
import ServiceExpireDate from "./service/ServiceExpireDate";
import SuccessfullPopup from "./SuccessfullPopup";
import { useEffect, useState } from "react";


//TODO: ADD EMAIL ADDRS
function SuccessfullBox(props) {
    const [fail, setFail] = useState(false);

    const onTest = async () => {
        if (!props.link) {
            setFail(true);

            setTimeout(() => {
                setFail(false);
            }, 5000);
        } else {
            window.open(props.link);
        }
    }

    const pdfBtnShower = () => {
        let pdfShower = true;

        switch (props.service) {
            case "SPA":
                pdfShower = false;
                break;
            case "SRA":
                pdfShower = false;
                break;
            case "SSD":
                pdfShower = false;
                break;
            case "PCL":
                pdfShower = false;
                break;
            case "PCO":
                pdfShower = false;
                break;

            default:
                break;
        }

        return pdfShower;
    }

    useEffect(() => {
        window.scroll(0, 0);
        if (document.getElementById("rating-block")) document.getElementById("rating-block").style.display = "block";
    }, [])


    const siteUrl = JSON.parse(localStorage.getItem("configuration")).data;

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-lg-10">
                    <div className="cmp-hero">
                        <section className="it-hero-wrapper bg-white align-items-start">
                            <div className="it-hero-text-wrapper pt-0 ps-0 pb-40 pb-lg-60">
                                <div className="categoryicon-top d-flex">
                                    <svg
                                        className="icon icon-success mr-10 icon-sm mb-1"
                                        aria-hidden="true"
                                    >
                                        <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-check-circle"></use>
                                    </svg>
                                    <h1
                                        className="text-black hero-title"
                                        data-element="page-name"
                                    >
                                        {props.successData.title}

                                    </h1>
                                </div>
                                <p className="titillium hero-text">
                                    {props.successData.message}
                                    <br />
                                    <br />
                                    Abbiamo inviato il riepilogo all'email:
                                    <br />
                                    <strong id="email-recap">{props.emailToSend}</strong>
                                </p>
                            </div>

                        </section>
                        {/* scarica ricevuta */}
                        {
                            pdfBtnShower() &&
                            <button onClick={onTest}
                                type="button" className="btn btn-outline-primary bg-white fw-bold">
                                <span className="rounded-icon">
                                    <svg className="icon icon-primary icon-sm" aria-hidden="true">
                                        <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-download"></use>
                                    </svg>
                                </span>

                                {
                                    props.service === "PTI" ?
                                        <span className="">Scarica F24</span> :
                                        <span className="">Scarica la ricevuta</span>
                                }
                            </button>
                        }

                        {
                            fail &&
                            <SuccessfullPopup
                                isError={true}
                                msg="F24 NON PRESENTE SE IMPOSTA E' 0" />
                        }
                    </div>
                    {/* {
                        props.service !== "SPA" && props.service !== "SSD" && props.service !== "SRA" &&
                        <p className="mt-3" style={{ marginTop: '50px !important' }}>
                            <a href={`/`} className="t-primary text-paragraph">Consulta la richiesta</a>
                            <span className="text-paragraph"> nella tua area riservata</span>
                        </p>
                    } */}

                    {
                        props.service !== "SPA" && props.service !== "SSD" && props.service !== "SRA" &&
                        <p className="mt-3 titillium hero-text" style={{ marginTop: '-10px !important' }}>
                            Puoi consultare lo stato della richiesta e scaricare la ricevuta in qualunque momento dalla tua &nbsp;
                            <a href={`/`} className="t-primary text-paragraph">area riservata.</a>
                        </p>
                    }
                    <ServiceExpireDate serviceId={props?.service} />
                </div>
            </div>

            <div >
                <HelpBox
                    helpBoxInfo={{
                        muniQuestionLink:
                            "https://stellanello.nemea.cloud/domande-frequenti/",
                        muniHelpLink: "https://stellanello.nemea.cloud/servizi/assistenza/",
                        greenNumber: "tel:+39 0182 668000",
                        bookLink: "https://stellanello.nemea.cloud/servizi/prenotazioni/",
                        rDisservice: "",
                    }}
                />
            </div>
        </div>

    )
}


export default SuccessfullBox;
