import React, { useState } from "react";
import FormBoxField from "../../UI-component/ui-el/FormBoxField";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import { useDispatch, useSelector } from "react-redux";
import { uniqueIdBuilder } from "../../util/Dynamics";
import NextBtnForm from "../../UI-component/NexBtnForm";
import stepSlice from "../../context/StepsContext";
import SuccessfullPopup from "../../UI-component/SuccessfullPopup";
import PopupSelectField from "../../UI-component/ui-el/PopupSelectField";
import { useEffect } from "react";
import { ImuApi } from "../../Apis/ImuApi";
import PreferenceContainer from "../../UI-component/preferencesForms/PreferenceContainer";
import CardContainer from "../../UI-component/registryForms/CardContainer";
import AdvancePayment from "../../UI-component/AdvancePayment";
import Balance from "../../UI-component/Balance";
import TotalPaymentInfo from "../../UI-component/TotalPaymentInfo";
import Loader from "../../UI-component/Loader";

export default function PagamentiIMURiepilogo(props) {
  const dispatcher = useDispatch();
  const ctx = useSelector(state => state.stepSlice.data);
  const ctxImu = useSelector(state => state.stepSlice.data.imu);

  const [taxPayer, setTaxPayer] = useState(ctx.imu.contribuente);
  const [coobData, setCoobData] = useState(ctx.imu.coobbligato);
  const [realtyData, setRealtyData] = useState(ctx.imu.immobili);

  const [year, setYear] = useState(ctx.imu.immobili[0].dati_immobile.anno);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const [fail, setFail] = useState({
    error: false,
    msg: ""
  });

  const isRealyOut = (tipologia_immobile) => {
    const id = tipologia_immobile.split("-")[0].trim();
    if (id === "0" || id === "1" || id === "24" || id === " 25")
      return true;

    return false;
  }

  const update = (data) => {

    const id = data.id.toLowerCase();

    switch (id) {
      case "contribuente":
        const obj = { ...data.data[0], selected: true };
        setTaxPayer([obj]);
        break;
      case "immobili":
        setRealtyData(prev => {
          const obj = structuredClone(prev);

          const idx = obj.findIndex(itm => {
            return itm.id === data.data.id;
          });

          obj[idx] = data.data;



          prev = obj;



          (async () => {
            const imu = structuredClone(ctxImu);
            imu.immobili = prev;

            await getDataPref(imu);
          })();

          return [...prev];
        });



        break;
      case "coobbligato":
        setCoobData(data.data);
        break;
      default:
        break;
    }
  }

  const onNext = () => {
    dispatcher(stepSlice.actions.dynamicSave({ id: "Contribuente", data: taxPayer }));
    dispatcher(stepSlice.actions.dynamicSave({ id: "Immobili", data: realtyData }));
    dispatcher(stepSlice.actions.dynamicSave({ id: "Coobbligato", data: coobData }));

    props.next();
  }

  const save = () => {
    setLoading(true);

    dispatcher(stepSlice.actions.dynamicSave({ id: "Contribuente", data: taxPayer }));
    dispatcher(stepSlice.actions.dynamicSave({ id: "Immobili", data: realtyData }));
    dispatcher(stepSlice.actions.dynamicSave({ id: "Coobbligato", data: coobData }));

    props.save();
    setLoading(false);

  }

  const onChangeYear = (e) => {
    setYear(e.target.options[e.target.selectedIndex].text);
  }

  const getData = async (type, year) => {
    try {
      const res = await ImuApi.getImuConfig(type, year);
      if (!res.success)
        throw new Error(res.message);

      setRealtyData(prev => {
        let obj = structuredClone(prev);
        if (Array.isArray(prev)) {

          obj.forEach((itm) => {
            itm.dati_comunali.aliquota_acconto = res.data?.aliquota_acconto !== 0 ? res.data?.aliquota_acconto : "Esente IMU";
            itm.dati_comunali.aliquota_saldo = res.data?.aliquota_saldo !== 0 ? res.data?.aliquota_saldo : "Esente IMU";
            itm.dati_immobile.anno = year;
          });
        }

        return [...obj];
      });
    } catch (err) {
      console.log(err);
    }
  }

  const getDataPref = async (imu = false) => {
    try {
      setLoading(true);
      const res = await ImuApi.sendRequest({ imu: imu !== false ? imu : ctxImu, f_24: true });

      if (!res.success) {
        // alert(res.message);
        setFail(prev => {
          return { ...prev, error: true, msg: `ERRORE:  ${res?.message ? res.message : "QUALCOSA E' ANDATO STORTO"}` }
        });

        setTimeout(() => {
          document.querySelector(".bkw-btn").click();
          return;
        }, 5000);
      }
      //TODO SAVE DATA IN THE CTX
      setData(res.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setFail(prev => {
        return { ...prev, error: true, msg: `ERRORE:  ${"QUALCOSA E' ANDATO STORTO"}` }
      });

      setTimeout(() => {
        document.querySelector(".bkw-btn").click();
        return;
      }, 5000);
      console.log(err);
    }
  }

  useEffect(() => {
    (async () => {
      if (Array.isArray(realtyData)) {
        for (let i of realtyData) {
          getData(i.dati_immobile?.tipologia_immobile.split(" -")[0], year);
        }
      }
    })();
  }, [year]);

  useEffect(() => {
    getDataPref();
  }, []);

  return (
    <div className="row">
      <div className="col-12 col-lg-8 offset-lg-2">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">
            <h2 className="title-xxlarge mb-4 mt-40">Dati generali</h2>
            <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-55%,-50%)", zIndex: "10000" }}>
              <Loader loading={loading} />
            </div>

            {
              taxPayer.map((ctr, i) => {
                if (ctr.selected) {
                  return (
                    <SummaryCard
                      registryData={ctr}
                      key={uniqueIdBuilder()}
                      mod={true}
                      cardTitle={`${ctr.anagrafica?.nome ?? ""} ${ctr.anagrafica?.cognome ?? ctr.anagrafica?.ragione_sociale}`}
                      type="Contribuenti"
                      taxID={ctr.anagrafica.codice_fiscale}
                      service={props.info.code}
                      update={update}>
                      {
                        ctr.type.type.includes("giuridica") ?
                          <div key={i}>
                            <FormBoxField title="Denominazione o ragione sociale" value={ctr.anagrafica?.ragione_sociale} />
                            <FormBoxField title="Codice Fiscale o Parita Iva" value={ctr.anagrafica?.codice_fiscale} />
                            <FormBoxField title="Telefono" value={ctr.contatti?.telefono} />
                            <FormBoxField title="Email" value={ctr.contatti?.email} />
                          </div> :
                          <div key={i}>
                            <FormBoxField title="Nome" value={ctr.anagrafica?.nome} />
                            <FormBoxField title="Cognome" value={ctr.anagrafica?.cognome} />
                            <FormBoxField title="Codice Fiscale" value={ctr.anagrafica?.codice_fiscale} />
                            <FormBoxField title="Data di nascita" value={ctr.anagrafica?.data_di_nascita} />
                            <FormBoxField title="Luogo di nascita" value={ctr.anagrafica?.luogo_di_nascita} />
                            <FormBoxField title="Provincia di nascita" value={ctr.anagrafica?.provincia_di_nascita || JSON.parse(JSON.parse(localStorage.getItem("user")).data).countyOfBirth} />
                            <FormBoxField title="Telefono" value={ctr.contatti?.telefono} />
                            <FormBoxField title="Email" value={ctr.contatti?.email} />

                          </div>
                      }
                    </SummaryCard>
                  )
                }
              })
            }

            <SummaryCard
              mod={true}
              cardTitle=""
              type="Coobligato"
              update={update}
              registryData={{ codice_fiscale: coobData?.codice_fiscale, identificativo_coobbligato: coobData?.identificativo_coobbligato }}>
              <FormBoxField
                title="Codice Fiscale"
                value={coobData?.codice_fiscale ?? "Non Applicato"} />
              <FormBoxField
                title="Identificativo coobligato"
                value={coobData?.identificativo_coobbligato ?? "Non Applicato"} />
            </SummaryCard>

            <SummaryCard type="Anno di riferimento">
              <PopupSelectField
                defaultValue={year}
                onChange={onChangeYear}
                id={"anno"}
                fieldTitle={"Anno"}
                options={JSON.parse(localStorage.getItem("configuration")).data?.current?.imuYears.split(",")}
                description="Seleziona l'anno di riferimento"
                key={uniqueIdBuilder()} />
            </SummaryCard>

            {
              realtyData.map((ctr, idx) => {
                const itm = ctr.dati_immobile;
                return (
                  <SummaryCard
                    key={uniqueIdBuilder()}
                    mod={true}
                    cardTitle={JSON.parse(localStorage.getItem("configuration"))?.data?.current?.description}
                    type="Immobili"
                    registryData={ctr}
                    update={update}>
                    <FormBoxField title="Categoria tipologia immobile" value={itm?.tipologia_immobile} />
                    {
                      itm?.catastale !== "" &&
                      <FormBoxField title="Catastale" value={itm?.catastale} />
                    }
                    <FormBoxField title="Altri proprietari residenti" value={itm?.numero_conviventi} />
                    <FormBoxField title="Rendita catastale" value={itm?.rendita_catastale} />
                    <FormBoxField title="Percentuale possesso" value={itm?.percentuale_di_possesso} />
                    <FormBoxField title="Immobile variato" value={itm?.immobile_variato} />
                    <FormBoxField title="Mese inizio possesso" value={itm?.mese_inizio} />
                    <FormBoxField title="Mese fine possesso" value={itm?.mese_fine} />

                    {
                      ctr.pertinenza_1.rendita_catastale !== "" && isRealyOut(ctr.dati_immobile.tipologia_immobile) &&
                      <div key={uniqueIdBuilder()}>
                        <div>
                          <h6 style={{ color: "black", padding: "20px", fontSize: "25px", borderBottom: "solid 1px lightgrey", textAlign: "left" }}>Pertinenza 1</h6>
                        </div>
                        <FormBoxField title="Rendita catastale" value={ctr.pertinenza_1?.rendita_catastale} />
                        <FormBoxField title="Percentuale possesso" value={ctr.pertinenza_1?.percentuale_di_possesso} />
                        <FormBoxField title="Mese inizio" value={ctr.pertinenza_1?.mese_inizio} />
                        <FormBoxField title="Mese fine" value={ctr.pertinenza_1?.mese_fine} />
                        <FormBoxField title="Immobile storico inagibile" value={ctr.pertinenza_1?.immobile_storico_inagibile || ctr.pertinenza_1?.Immobile_storico_inagibile} />

                      </div>
                    }

                    {
                      ctr.pertinenza_2.rendita_catastale !== "" && isRealyOut(ctr.dati_immobile.tipologia_immobile) &&
                      <div key={uniqueIdBuilder()}>
                        <div>
                          <h6 style={{ color: "black", padding: "20px", fontSize: "25px", borderBottom: "solid 1px lightgrey", textAlign: "left" }}>Pertinenza 2</h6>
                        </div>
                        <FormBoxField title="Rendita catastale" value={ctr.pertinenza_2?.rendita_catastale} />
                        <FormBoxField title="Percentuale possesso" value={ctr.pertinenza_2?.percentuale_di_possesso} />
                        <FormBoxField title="Mese inizio" value={ctr.pertinenza_2?.mese_inizio} />
                        <FormBoxField title="Mese fine" value={ctr.pertinenza_2?.mese_fine} />
                        <FormBoxField title="Immobile storico o inagibile" value={ctr.pertinenza_2?.immobile_storico_inagibile || ctr.pertinenza_2?.Immobile_storico_inagibile} />

                      </div>
                    }

                    {
                      ctr.pertinenza_3.rendita_catastale !== "" && isRealyOut(ctr.dati_immobile.tipologia_immobile) &&
                      <div key={uniqueIdBuilder()}>
                        <div>
                          <h6 style={{ color: "black", padding: "20px", fontSize: "25px", borderBottom: "solid 1px lightgrey", textAlign: "left" }}>Pertinenza 3</h6>
                        </div>
                        <FormBoxField title="Rendita catastale" value={ctr.pertinenza_3?.rendita_catastale} />
                        <FormBoxField title="Percentuale possesso" value={ctr.pertinenza_3?.percentuale_di_possesso} />
                        <FormBoxField title="Mese inizio" value={ctr.pertinenza_3?.mese_inizio} />
                        <FormBoxField title="Mese fine" value={ctr.pertinenza_3?.mese_fine} />
                        <FormBoxField title="Immobile storico inagibile" value={ctr.pertinenza_3?.immobile_storico_inagibile || ctr.pertinenza_3?.Immobile_storico_inagibile} />

                      </div>
                    }
                  </SummaryCard>
                )
              })
            }


            {
              Array.isArray(data) &&
              data?.map((itm, idx) => {
                const asset = itm?.imposta_immobili;

                return (
                  <PreferenceContainer key={uniqueIdBuilder()}>
                    <CardContainer title={asset.nome_tipologia}>
                      <AdvancePayment
                        expiryDate={asset?.data_di_scadenza_acconto}
                        timeLeft={asset?.giorni_dalla_scadenza_acconto}
                        total={asset?.totale_da_versare}
                      />
                      <Balance
                        expiryDate={asset?.data_di_scadenza_saldo}
                        timeLeft={asset?.giorni_dalla_scadenza_saldo}
                        total={asset?.totale_da_versare}
                      />
                      <TotalPaymentInfo totalToBePaid={asset?.totale_da_versare + "€"} />
                    </CardContainer>

                  </PreferenceContainer>
                )
              })
            }


          </div>
        </div>
        <NextBtnForm
          saveRequest={save}
          back={props.back}
          send={() => { props.send() }}
          last={true}

        />

        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }
      </div>
    </div>
  );
}
