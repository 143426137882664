import { useEffect, useState } from "react";

import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import WorkPath from "../../UI-component/WorkPath";
import DynamicStepContent from "../../UI-component/DynamicStepContent";
import First from "../_Partials/First";
import Second from "../_Partials/Second";
import Third from "../_Partials/Third";
import Fourth from "../_Partials/Fourth";
import Fifth from "../_Partials/Fifth";
import { useDispatch } from "react-redux";
import stepSlice from "../../context/StepsContext";
import Privacy from "../_Partials/Privacy";
import { useLocation } from "react-router-dom";
import { ServiceDetailsAPI } from "../../Apis/ServiceDetailsAPI";

export default function PrenotaAppuntamento(props) {
  const dispatcher = useDispatch();

  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");
  const location = useLocation();
  const [prefData, setPrefData] = useState([]);
  const [bread, setBread] = useState({});

  //initialize localStorage conf once when component mounts ?
  useEffect(() => {
    //inizioalize first view first step
    if (localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);
    }
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
    }

    if (location.state) {
      dispatcher(stepSlice.actions.onDraftData({ data: location.state.data, saved: false }));
    }


    if (!location.state) {
      (async () => {
        try {
          const saved = await ServiceDetailsAPI.getSaved("spa");

          if (!saved.success) {
            return;
          }

          setPrefData(saved?.data);
          console.log(saved);

          dispatcher(stepSlice.actions.onDraftData({ data: saved?.data, saved: false }));

        } catch (err) {
          console.log(err);
        }
      })()
    }

    (async () => {
      try {
        const bread = await ServiceDetailsAPI.getBreadcrumbs("SPA");
        setBread(bread);
      } catch (err) {
        console.log(err);
      }
    })()
  }, []);

  useEffect(() => {
    // clearStore();
  }, []);

  const clearStore = () => {
    dispatcher(stepSlice.actions.clear())
  }

  return (
    <AuthenticatedTenant auth={user} errors={props.errors} header={header}>
      <main>
        <WorkPath workPathInfo={{
          base: ["Home", header?.siteUrl],
          medium: ["Servizi", header?.siteUrl + "servizi/"],
          category: "",
          serviceName: [bread.service_name, bread.service_url],
          end: "Servizio digitale",
        }} />

        <div className="container" id="main-container">
          <div className="row justify-content-center" >
            <DynamicStepContent noPriv={true} service={props.code}
              successTitle={"Prenotazione appuntamento"}
              successDesc={`
                   Inserisci le informazioni necessarie per prenotare l'appuntamento.<br />
                   Potrai ricontrollare tutti i dati nel riepilogo, prima di
                   inviare la richiesta.`}
              stepList={["Privacy", "Luogo", "Data e orario", "Dettagli appuntamento", "Richiedente", "Riepilogo"]}
              steps={[
                {
                  component: Privacy, info: {
                    description: `Il ${JSON.parse(localStorage.getItem("configuration")).data.current.description}
                    esercita compiti e funzioni e gestisce servizi come indicato negli artt. 13/14 del Testo Unico Enti Locali (D.lgs. 267/2000 e ss integrazioni).
                    In questo contesto procede al trattamento dei dati personali dell’interessato necessari per fornire ogni tipo 
                    di servizio nel rispetto della normativa vigente (codice protezione dati personali e Regolamento europeo 16/679).
                    Per una completa informazione si invita a prendere conoscenza del contenuto della informativa resa ex art. 13
                    Regolamento europeo.`,
                    linkPrivacy: "",
                  }
                },
                { component: First, info: {} },
                { component: Second, info: { disp: "disp" } },
                { component: Third, info: {} },
                { component: Fourth, info: { data: prefData } },
                { component: Fifth, info: {} },
              ]} />
          </div>
        </div>
      </main>
    </AuthenticatedTenant>
  );
}
