import React, { useEffect, useState } from "react";
import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import WorkPath from "../../UI-component/WorkPath";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import FormBoxField from "../../UI-component/ui-el/FormBoxField";
import PopupTextField from "../../UI-component/ui-el/PopupTextField";
import { useLocation } from "react-router-dom";
import { PaymentApi } from "../../Apis/PaymentApi";
import Loader from "../../UI-component/Loader";
import SuccessfullPopup from "../../UI-component/SuccessfullPopup";
import { ServiceDetailsAPI } from "../../Apis/ServiceDetailsAPI";
import StepHeader from "../../UI-component/StepHeader";
import CompactInfo from "../../UI-component/CompactInfo";

function PaymentPaPage(props) {
  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");
  const location = useLocation();
  const [importo, setImporto] = useState(location.state?.data?.amount ?? 0);
  const siteUrl = JSON.parse(localStorage.getItem("configuration"));

  const [completeData, setCompleteData] = useState({
    iuv: "",
    institutionCode: ""
  });

  const [file, setFile] = useState("");

  const [loading, setLoading] = useState(false);

  const [paymentShow, setPaymentShow] = useState(location?.state?.data?.iuv ? true : false);
  const [pagoPaUrl, setPagoPaUrl] = useState("");
  const [bread, setBread] = useState({});

  const [error, setError] = useState(false);

  // console.log(importo);
  useEffect(() => {
    //inizioalize first view first step
    if (localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);
    }
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
    }


  }, []);

  const change = (e) => {
    setImporto(e.target.value);
  }

  const onCreateDebit = async () => {
    const state = location.state.data;

    try {
      setError(false);

      const data = {
        id: state.id,
        causal: state.title,
        total: importo,
        paymentCode: state.fileN,
        code: state.code
      }

      setLoading(true);

      const res = await PaymentApi.createDebit({ ...data });

      setPagoPaUrl(res.data.url_pagopa);

      if (!res.success)
        console.log("err");


      setCompleteData(prev => {
        return { ...prev, iuv: res?.data?.iuv, institutionCode: res.data?.codice_ente };
      });

      setFile(res?.data?.bollettino);

      setLoading(false);
      setPaymentShow(true);

    } catch (err) {
      console.log(err);
      setLoading(false);
      setError(true);
    }
  }

  const ownPayments = (siteUrl) => {
    let url = "";
    /* if (siteUrl.toLowerCase().includes("brigaalta")) {
      url = "https://www.servizipubblicaamministrazione.it/servizi/portalecontribuente/AreaPagamenti.aspx?iddominio=00553180043";
    }
    else  */
    
    if (siteUrl.toLowerCase().includes("camporosso")) {
      url = "https://www.halleyweb.com/c008011/po/po_login.php";
    }
    else if (siteUrl.toLowerCase().includes("tiglieto")) {
      url = "https://www.halleyweb.com/c010061/po/po_login.php";
    }
    else if (siteUrl.toLowerCase().includes("brugnato")) {
      url = "https://www.halleyweb.com/c011007/po/po_login.php";
    } else if (siteUrl.toLowerCase().includes("levanto")) {
      url = "https://halleyweb.com/c011017/po/po_login.php";
    }

    return (
      <div className="steppers-content mt-40" aria-live="polite">
        <div className="it-page-sections-container"></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10">
              <div className="cmp-hero">
                <section className="it-hero-wrapper bg-white align-items-start">
                  <div className="it-hero-text-wrapper pt-0 ps-0 pb-40 pb-lg-60">
                    <div className="categoryicon-top d-flex">
                      <svg
                        className="icon icon-success mr-10 icon-sm mb-1"
                        aria-hidden="true"
                      >
                        <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-check-circle"></use>
                      </svg>
                      <h1
                        className="text-black hero-title"
                        data-element="page-name"
                      >
                        Richiesta pagamento effettuata
                      </h1>
                    </div>

                    <p className="titillium hero-text">
                      Grazie per la tua richiesta, cliccando su “Vai al pagamento” sarai reindirizzato alla
                      &nbsp;<a href={url} target="_blank" rel="noreferrer">pagina esterna</a>
                      &nbsp;dove potrai effettuare il pagamento con PagoPA per il servizio {location.state?.data?.serviceNameLink}.
                      <br />
                      <br />
                    </p>
                  </div>
                </section>
                <a href={url}
                  target="__blank"
                  className="btn btn-icon btn-re square mb-2 btn-color">
                  <span >Vai al pagamento</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const onPostalOrder = async () => {
    if (file === "") {
      try {
        setError(false);

        setLoading(true);

        const res = await PaymentApi.getBullettin(
          {
            "service_id": location?.state?.data?.fileN?.split("-")[0],
            "codice_fiscale": JSON.parse(localStorage.getItem("user"))?.fiscal_code,
            "practice_number": location?.state?.data?.fileN
          }
        );

        if (!res.success)
          console.log("err");

        window.open(res.data.bollettino, "__blank");

        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
        setError(true);
      }
    } else
      window.open(file, "__blank");

  }

  const retrieveUrl = async () => {
    try {
      const email = JSON.parse(JSON.parse(localStorage.getItem("user")).data).email;

      const payload = {
        "email": location.state?.data?.email || email,
        "paymentCode": location.state?.data?.fileN,
        "iuv": location?.state?.data?.iuv,
        "total": importo === 0 ? location?.state?.data?.amount : importo,
        "causal": location.state?.data?.title,
        "serviceName": location.state?.data?.serviceNameLink
      }


      // console.log("url payload", payload);
      const res = await PaymentApi.recreateUrl(payload);

      setPagoPaUrl(res.data);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    // console.log("enter hereee", location.state);
    if (paymentShow) {
      retrieveUrl();
    }

    (async () => {
      try {
        const bread = await ServiceDetailsAPI.getBreadcrumbs(location.state?.data?.fileN.split("-")[0]);
        setBread(bread);

        if (document.getElementById("rating-block")) document.getElementById("rating-block").style.display = "block";

      } catch (err) {
        console.log(err);
      }
    })()


  }, []);

  return (
    <AuthenticatedTenant auth={user} errors={props?.errors} header={header}>
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: "1000"
        }}>
        <Loader loading={loading} />
      </div>

      <WorkPath workPathInfo={{
        base: ["Home", header?.siteUrl],
        medium: ["Servizi", header?.siteUrl + "servizi/"],
        category: [bread.category_name, bread.category_url],
        serviceName: [bread.service_name, bread.service_url],
        end: "Pagamento",
      }} />

      <div className="container" id="main-container">
        <div className="row justify-content-center" >
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10">
              <CompactInfo title={location.state?.data?.headTitle} description={location.state?.data?.headDesc} />
            </div>
          </div>
          <StepHeader step={4} stepList={["INFORMATIVA SULLA PRIVACY", "DATI GENERALI", "DATI SPECIFICI", "RIEPILOGO", "PAGAMENTO"]} />
        </div>
      </div>

      {
          /* siteUrl.data?.current.siteUrl.toLowerCase().includes("brigaalta") || */
          siteUrl.data?.current.siteUrl.toLowerCase().includes("camporosso") ||
          siteUrl.data?.current.siteUrl.toLowerCase().includes("tiglieto") ||
          siteUrl.data?.current.siteUrl.toLowerCase().includes("brugnato") ||
          siteUrl.data?.current.siteUrl.toLowerCase().includes("levanto") ?
          <>
            {ownPayments(siteUrl.data?.current.siteUrl)}
          </> :
          !paymentShow ?
            <div className="row">
              <div className="col-12 col-lg-8 offset-lg-2">
                <div className="steppers-content" aria-live="polite">
                  <div className="it-page-sections-container">
                    <h1 style={{ borderBottom: "solid 1px lightgrey", marginBottom: "10px", padding: "30px 0 30px 0" }}></h1>
                    <br></br>
                    <SummaryCard cardTitle="Dati" type={/*location.state?.data?.serviceNameLink*/ ""}>
                      <FormBoxField
                        title="Pratica"
                        value={location.state?.data?.fileN} />
                      <FormBoxField
                        title="Data di pagamento"
                        value={location.state?.data?.date} />
                      <FormBoxField
                        title="Importo totale dovuto"
                        value={importo + " €"} />
                      <FormBoxField
                        title="Descrizione pagamento"
                        value={location.state?.data?.title} />
                      <br></br>
                      <SummaryCard cardTitle="Importo pagamento" type="">
                        <PopupTextField
                          defaultValue={importo}
                          onChange={change}
                          fieldType="number"
                          id="importo"
                          fieldTitle="Importo*"
                          disabled={location?.state?.data?.fileN?.startsWith("PCL") ||
                            location?.state?.data?.fileN?.startsWith("PCO") ? true : false
                          }

                        />
                      </SummaryCard>
                    </SummaryCard>
                    <div style={{ display: "flex", flexDirection: "column", marginBottom: "30px" }}>
                      <button type="button" className="btn btn-icon btn-re square mb-2" data-focus-mouse="false" onClick={onCreateDebit}>
                        <img src="../assets/images/pagopa.svg" alt="paga con pagoPA" className="me-2" />
                        <span className="">paga con pagoPA</span>
                      </button>
                      <a className="simple-link" href="#" data-focus-mouse="false">Che cos’è pagoPA <span className="visually-hidden">Che cos’è pagoPA</span></a>
                    </div>
                  </div>
                </div>
                {
                  error &&
                  <SuccessfullPopup isError={true}></SuccessfullPopup>
                }
              </div>

            </div> :
            <div className="row">
              <div className="col-12 col-lg-8 offset-lg-2">
                <div style={{ marginLeft: "-100px" }}>
                </div>
                <div className="steppers-content" aria-live="polite">
                  <div className="it-page-sections-container">
                    <h1 style={{ borderBottom: "solid 1px lightgrey", marginBottom: "10px", padding: "30px 0 30px 0" }}></h1>
                    <br></br>
                    <SummaryCard cardTitle="Dati" type={/*location.state?.data?.serviceNameLink*/ ""}>
                      <FormBoxField
                        title="Codice Avviso (IUV)"
                        value={completeData?.iuv || location?.state?.data?.iuv}
                      />
                      <FormBoxField
                        title="Codice Fiscale Ente Creditore"
                        value={completeData?.institutionCode || JSON.parse(localStorage.getItem("configuration")).data?.current?.partitaIva}
                      />
                      <FormBoxField
                        title="Importo totale dovuto"
                        value={importo + " €"} />

                    </SummaryCard>
                    <div style={{ display: "flex", padding: "10px" }}>
                      <div style={{ display: "flex", flexDirection: "column", marginRight: "20px" }}>
                        <a href={pagoPaUrl !== "" ? pagoPaUrl : "https://checkout.pagopa.it/inserisci-dati-avviso"}
                          className="btn btn-icon btn-re square mb-2">
                          <img src="../assets/images/pagopa.svg" alt="paga con pagoPA" className="me-2" />
                          <span className="">paga online</span>
                        </a>
                        <a className="simple-link" href="https://www.pagopa.gov.it/" data-focus-mouse="false">Che cos’è pagoPA <span className="visually-hidden">Che cos’è pagoPA</span></a>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <button
                          type="button"
                          className="btn btn-icon btn-re square mb-2"
                          data-focus-mouse="false"
                          onClick={onPostalOrder}>
                          <img src="../assets/images/pagopa.svg" alt="paga con pagoPA" className="me-2" />
                          <span className="">paga con bollettino</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      }
    </AuthenticatedTenant >
  )
}

export default PaymentPaPage;