import React, { useEffect, useState } from "react";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import FormBoxField from "../../UI-component/ui-el/FormBoxField";

import { useDispatch, useSelector } from "react-redux";
import { OfficeAPI } from "../../Apis/OfficeAPI";
import NextBtnForm from "../../UI-component/NexBtnForm";
import Loader from "../../UI-component/Loader";
import SuccessfullPopup from "../../UI-component/SuccessfullPopup";
import stepSlice from "../../context/StepsContext";

//OFFICE SELECTOR
function Fifth(props) {
    const dispatcher = useDispatch();

    const ctx = useSelector(state => state.stepSlice.data.ufficio);
    const [officeDetails, setOfficeDetails] = useState({});

    const [applicant, setApplicant] = useState(ctx?.richiedente);
    const [loading, setLoading] = useState(false);

    const getOfficeDetails = async () => {
        try {
            setLoading(true);
            const res = await OfficeAPI.getOfficesById(ctx.ufficio.id);

            if (!res.success) {
                return;
            }
            setOfficeDetails(res.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    const update = (data) => {

        const appl = data.data[0];

        setApplicant({
            nome: appl.anagrafica.nome,
            cognome: appl.anagrafica.cognome,
            email: appl.anagrafica.email,
            telefono: appl.anagrafica.telefono,
            codice_fiscale: appl.anagrafica.codice_fiscale,
        });
    }

    const onNext = () => {
        const data = structuredClone(ctx);
        data.richiedente = applicant;

        props.send({ ufficio: data });
    }

    const save = async () => {

        const data = {
            ufficio: ctx.ufficio,
            appuntamenti_disponibili: ctx.appuntamenti_disponibili,
            disponibilita: ctx.disponibilita,
            motivo: ctx.motivo,
            dettagli: ctx.dettagli,
            richiedente: applicant
        }

        setLoading(true);

        await props.save(data);

        dispatcher(stepSlice.actions.dynamicSave({ id: "Ufficio", internalId: "richiedente", data: applicant }));

        setLoading(false);
    }


    useEffect(() => {
        getOfficeDetails();
    }, [])

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-12 col-lg-8 offset-lg-2">
                    <div className="steppers-content" aria-live="polite">
                        <div className="it-page-sections-container">
                            <SummaryCard cardTitle="Ufficio" type="Riepilogo">
                                <FormBoxField title="Indirizzo" value={ctx.ufficio?.value ?? "Non Compilato"} />
                                <FormBoxField title="Apertura" value={officeDetails.timetables} />
                            </SummaryCard>

                            <SummaryCard cardTitle="Dettagli appuntamento" >
                                <FormBoxField title="Motivo" value={ctx?.motivo ?? "Non Compilato"} />
                                <FormBoxField title="Dettagli" value={ctx?.dettagli ?? "Non Compilato"} />
                            </SummaryCard>

                            <SummaryCard cardTitle="Data e orario" >
                                <FormBoxField title="Data" value={ctx.appuntamenti_disponibili?.value ?? "Non Compilato"} />
                                <FormBoxField title="Orario" value={ctx.disponibilita?.value ?? "Non Compilato"} />
                            </SummaryCard>

                            <SummaryCard
                                mod={true}
                                cardTitle="Richiedente"
                                title="ufficio"
                                id="richiedente"
                                service={"SPA"}
                                type="richiedente"
                                registryData={applicant}
                                update={update}
                            >
                                <FormBoxField title="Nome" value={applicant?.nome ?? "Non Compilato"} />
                                <FormBoxField title="Cognome" value={applicant?.cognome ?? "Non Compilato"} />
                                <FormBoxField title="Codice Fiscale" value={applicant?.codice_fiscale ?? "Non Compilato"} />
                                <FormBoxField title="Telefono" value={applicant?.telefono ?? "Non Compilato"} />
                                <FormBoxField title="Email" value={applicant?.email ?? "Non Compilato"} />

                            </SummaryCard>
                        </div>
                    </div>
                    <Loader loading={loading} />

                    <NextBtnForm
                        saveRequest={save}
                        send={onNext}
                        back={props.back}
                        last={true}
                        noSave={false} />
                    {
                        props.saved &&
                        <SuccessfullPopup />
                    }

                    {
                        props.fail &&
                        <SuccessfullPopup isError={true} />
                    }
                </div>
            </div>
        </React.Fragment>
    )
}


export default Fifth;