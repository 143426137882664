import React, { useState } from "react";
import { useSelector } from "react-redux";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import FormBoxField from "../../../UI-component/ui-el/FormBoxField";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import Loader from "../../../UI-component/Loader";

function Summary(props) {
  const ctxApplicant = useSelector(state => state.stepSlice.data.richiedente[0]);
  const ctxAcustic = useSelector(state => state.stepSlice.data.disabile[0]);
  const ctxPref = useSelector(state => state.stepSlice.data.preferenze);
  const ctx = useSelector(state => state.stepSlice.data);

  const [applicant, setApplicant] = useState(ctxApplicant);
  const [acustic, setAcustic] = useState(ctxAcustic);
  const [prefs, setPrefs] = useState(ctxPref);
  const [loading, setLoading] = useState(false);

  const onNext = async () => {
    const data = {
      richiedente: [ctxApplicant],
      disabile: [ctxAcustic],
      savedDis: ctx?.savedDis,
      preferenze: prefs
    };

    props.send(data);
  }

  const save = async () => {
    const data = {
      richiedente: [{ ...ctxApplicant, selected: true }],
      disabile: [{ ...ctxAcustic, selected: true }],
      savedDis: ctx?.savedDis,
      preferenze: prefs
    }

    setLoading(true);

    await props.save(data);

    setLoading(false);
  }

  const update = (data) => {
    if (data.id === "Richiedente")
      setApplicant(data.data[0]);
    else if (data.id === "Disabile")
      setAcustic(data.data[0]);
    else if (data.id === "Preferenze") {
      const park = data.data.parcheggio;

      const obj = structuredClone(data.data)

      obj.parcheggio.tipo_di_invalidita = Array.isArray(park.tipo_di_invalidita) ? park.tipo_di_invalidita : [park.tipo_di_invalidita];
      obj.parcheggio.chiede = Array.isArray(park.chiede) ? park.chiede : [park.chiede];

      setPrefs(obj);
    }
  }

  return (

    <div className="row">
      <div className="col-12 col-lg-8 offset-lg-2">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">
            <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-55%,-50%)", zIndex: "10000" }}>
              <Loader loading={loading} />
            </div>

            <SummaryCard
              type="richiedente"
              cardTitle={`${applicant?.anagrafica?.nome} ${applicant?.anagrafica?.cognome}`}
              mod={true}
              update={update}
              id={"Richiedente"}
              taxID={applicant?.anagrafica.codice_fiscale}
              service={props.info.code}
              registryData={applicant} >
              <FormBoxField title="Tipo richiedente" value={applicant?.anagrafica.tipo_richiedente} />
              <FormBoxField title="Nome" value={applicant?.anagrafica.nome} />
              <FormBoxField title="Cognome" value={applicant?.anagrafica.cognome} />
              <FormBoxField title="Codice Fiscale" value={applicant?.anagrafica.codice_fiscale} />
              <FormBoxField title="Data di nascita" value={applicant?.anagrafica.data_di_nascita} />
              <FormBoxField title="Luogo di nascita" value={applicant?.anagrafica.luogo_di_nascita} />
              <FormBoxField title="Indirizzo residenza" value={applicant?.indirizzi.residenza} />
              <FormBoxField title="Comune residenza" value={applicant?.indirizzi.domicilio} />
              <FormBoxField title="Telefono" value={applicant?.contatti.telefono} />
              <FormBoxField title="Email" value={applicant?.contatti.email} />
              <FormBoxField title="Carta di identità " value={applicant?.documenti.carta_di_identita} />
              <FormBoxField title="Attestazione la potestà, tutore legale o curatore" value={applicant?.documenti?.attestazione_esercente || "Non compilato"} />
            </SummaryCard>

            <SummaryCard
              type="Disabile"
              cardTitle={`${acustic?.anagrafica?.nome} ${acustic?.anagrafica?.cognome}`}
              mod={true}
              update={update}
              id={"Disabile"}
              taxID={acustic?.anagrafica.codice_fiscale}
              service={props.info.code}
              registryData={acustic} >
              <FormBoxField title="Nome" value={acustic?.anagrafica.nome} />
              <FormBoxField title="Cognome" value={acustic?.anagrafica.cognome} />
              <FormBoxField title="Codice Fiscale" value={acustic?.anagrafica.codice_fiscale} />
              <FormBoxField title="Data di nascita" value={acustic?.anagrafica.data_di_nascita} />
              <FormBoxField title="Luogo di nascita" value={acustic?.anagrafica.luogo_di_nascita} />
              <FormBoxField title="Indirizzo residenza" value={acustic?.indirizzi.residenza} />
              <FormBoxField title="Comune residenza" value={acustic?.indirizzi.domicilio} />
              <FormBoxField title="Telefono" value={acustic?.contatti.telefono} />
              <FormBoxField title="Email" value={acustic?.contatti.email} />
              <FormBoxField title="Carta di identità" value={acustic?.documenti.carta_di_identita} />
              <FormBoxField title="Fototessera" value={acustic?.documenti.fototessera} />
              <FormBoxField title="Certificato dell’Ufficio medico legale dell'ASL competente per territorio (in caso di rilascio)" value={acustic?.documenti.certificato_ufficio || "Non compilato"} />
              <FormBoxField title="Certificato del medico curante - modello C4, ai sensi della D.G.R. 138/2015, ogni singola spunta deve essere affiancata dal timbro e firma del medico che rilascia la certificazione ai sensi della D.G.R. 442/2017 (in caso di rinnovo)" value={acustic?.documenti.certificato_medico || "Non compilato"} />
              <FormBoxField title="Denuncia di smarrimento o furto (in caso di duplicato)" value={acustic?.documenti.denuncia || "Non compilato"} />
            </SummaryCard>

            <SummaryCard
              type="Preferenze"
              cardTitle="Preferenze di servizio"
              mod={true}
              service="PPI"
              registryData={ctx}
              choiceData={[
                {
                  "options": [
                    {
                      "id": 1,
                      "value": "Permanente"
                    },
                    {
                      "id": 2,
                      "value": "Temporanea,inferiore ad anni 5"
                    },
                  ],
                  "title": [
                    "PARCHEGGIO",
                    "TIPO DI INVALIDITA"
                  ],
                  "type": "Select"
                },
                {
                  "options": [
                    {
                      "id": 1,
                      "value": "Rilascio del contrassegno"
                    },
                    {
                      "id": 2,
                      "value": "Rinnovo del contrassegno"
                    },
                    {
                      "id": 2,
                      "value": "Duplicato del contrassegno"
                    },
                  ],
                  "title": [
                    "PARCHEGGIO",
                    "CHIEDE"
                  ],
                  "type": "Select"
                },
                {
                  "options": [
                    {
                      "id": 1,
                      "title": "Vecchio contrassegno"
                    },
                  ],
                  "title": [
                    "PARCHEGGIO",
                    "VECCHIO CONTRASSEGNO"
                  ],
                  "child_title": [
                  ],
                  "type": "MultiText"
                },

                {
                  "options": [
                    {
                      "id": 1,
                      "title": "numero"
                    },
                    {
                      "id": 1,
                      "title": "data"
                    },
                  ],
                  "title": [
                    "PARCHEGGIO",
                    "MARCA DA BOLLO"
                  ],
                  "child_title": [
                    "Numero",
                    "Data"
                  ],
                  "type": "MultiText"
                },

              ]
              }
              update={update}>
              <FormBoxField title="Tipo di invalidità" value={prefs?.parcheggio?.tipo_di_invalidita[0] || "Non compilato"} />
              <FormBoxField title="Chiede" value={prefs?.parcheggio?.chiede[0] || "Non compilato"} />
              <FormBoxField title="Vecchio contrassegno" value={prefs?.parcheggio?.vecchio_contrassegno || "Non compilato"} />
              {
                prefs?.parcheggio?.marca_da_bollo.numero && (
                  <>
                    <FormBoxField title="Numero" value={prefs?.parcheggio?.marca_da_bollo.numero || "Non compilato"} />
                    <FormBoxField title="Data" value={prefs?.parcheggio?.marca_da_bollo?.data || "Non compilato"} />
                  </>
                )
              }
            </SummaryCard>

            <NextBtnForm
              send={onNext}
              back={props.back}
              saveRequest={save}
              last={true}
              noSave={false}
            />
            {
              props.saved &&
              <SuccessfullPopup />
            }

            {
              props.fail &&
              <SuccessfullPopup isError={true} />
            }
          </div>
        </div>
      </div>
    </div>
  )
}


export default Summary;