import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PaymentApi } from "../Apis/PaymentApi";
import Loader from "./Loader";

function CompletitionBoxMsg(props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toPay, setToPay] = useState(props.pay);

  const click = () => {
  }

  const onPay = () => {
    const siteUrl = JSON.parse(localStorage.getItem("configuration"));

    /* if (siteUrl.data?.current.siteUrl.toLowerCase().includes("brigaalta")) {
      window.open(
        'https://www.servizipubblicaamministrazione.it/servizi/portalecontribuente/AreaPagamenti.aspx?iddominio=00553180043', "_blank"
      );
    } else  */
    
    if (siteUrl?.data.current.siteUrl.toLowerCase().includes("camporosso")) {
      window.open(
        'https://www.halleyweb.com/c008011/po/po_login.php', "_blank"
      );
    } else if (siteUrl?.data.current.siteUrl.toLowerCase().includes("tiglieto")) {

      window.open(
        'https://www.halleyweb.com/c010061/po/po_login.php', "_blank"
      );
    } else if (siteUrl?.data.current.siteUrl.toLowerCase().includes("brugnato")) {

      window.open(
        'https://www.halleyweb.com/c011007/po/po_login.php', "_blank"
      );
    } else if (siteUrl?.data.current.siteUrl.toLowerCase().includes("levanto")) {

      window.open(
        'https://halleyweb.com/c011017/po/po_login.php', "_blank"
      );
    }

    navigate("/payment-page", { state: { data: props } });
  }

  const onUpdatePaymentState = async () => {
    // console.log("payment status", props);
    try {
      setLoading(true);
      const response = await PaymentApi.updatePaymentState({
        id: props.payID,
        iuv: props.iuv,
        type: props.type
      });

      setToPay(!response.data.StatoPagamento);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  return (
    <div className="accordion-item" onClick={click}>
      <div className="accordion-header" id="heading7">

        <div>
          <button
            className="accordion-button collapsed title-snall-semi-bold"
            type="button"
            onClick={() => setOpen(prev => !prev)}
          >
            <div className="button-wrapper">
              {props.title}
              <div className="icon-wrapper">

                {
                  toPay ?
                    <>
                      <img
                        className="icon-folder"
                        src="../assets/images/folder-to-pay.svg"
                        alt="folder Da pagare"
                      />
                      <span className="u-main-error">Da pagare</span>
                    </> :
                    <>
                      <img
                        className="icon-folder"
                        src="../assets/images/folder-pay.svg"
                        alt="folder Pagato"
                      />
                      <span className="">Pagato</span>
                    </>
                }
              </div>
            </div>
          </button>
          <p className="accordion-date title-xsmall-regular mb-0">
            {toPay && `Data generazione del pagamento:  ${props.date}`}
            {!toPay && `Data del pagamento:  ${props.date}`}
          </p>
        </div>
        {
          open &&
          <div>
            <div className="accordion-body">
              <p className="mb-2 fw-normal">Pagamento:
                <span className="label"> {props?.fileN?.toUpperCase()}</span>
              </p>
              <p className="mb-2 fw-normal">
                Metodo: <span className="label"> {props.paymentMethod ? props.paymentMethod : "Non settato"}</span>
              </p>
              <p className="mb-2 fw-normal">
                Importo: <span className="label"> {props.amount}€</span>
              </p>
              <a style={{ marginRight: "10px" }} href={props?.link} className="mb-2">
                <span className="t-primary">
                  Scheda servizio
                </span>
              </a>
              {
                toPay &&
                !props.serviceLink.includes("imu") &&
                !window.location.href.includes("quindici") &&
                <>
                  <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-55%,-50%)", zIndex: "10000" }}>
                    <Loader loading={loading} />
                  </div>
                  <button
                    onClick={onPay}
                    style={{ padding: "8px", background: "var(--primary)" }}
                    type="button"
                    className="btn btn-icon btn-re square"
                    data-focus-mouse="false">Vai al pagamento
                  </button>
                  {
                    props.iuv && props?.type !== "ypnos" &&
                    <button
                      onClick={onUpdatePaymentState}
                      style={{ padding: "8px", background: "white", color: "var(--primary)", boxShadow: "0 .3px 2px grey", marginTop: ".5rem" }}
                      type="button"
                      className="btn btn-icon btn-re square"
                      data-focus-mouse="false">Aggiorna lo stato del pagamento
                    </button>
                  }
                </>
              }
            </div>
          </div>

        }
      </div>

    </div>
  )
}

export default CompletitionBoxMsg;