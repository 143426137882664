import { api } from "./Configs/AxiosConfigs";
import { defineCancelApiObject } from "./Configs/AxiosUtils";

export const ConfigurationAPI = {
  getConfiguration: async function (cancel = false) {
    const frontendUrl = window.location.href;
    const response = await api.request({
      url: "/configuration/",
      method: "GET",
      headers: {
        'X-Frontend-URL': frontendUrl,
        'X-Token': localStorage.getItem('access_token')
      },
      signal: cancel ? cancelApiObject[this.getConfiguration.name].handleRequestCancellation().signal : undefined,
    });

    const data = response.data;
    if (data.success) {
      // Aggiorna il DOM con il nuovo url "Prenota appuntamento" del footer
      const appointmentLink = document.querySelector("#menu-info-1 > li:nth-child(2) > a");
      if (appointmentLink) {
        appointmentLink.href = data.data.newUrl;
      }

      // Aggiorna il DOM con il nuovo url "Prenota appuntamento" del dox contatti
      const additionalLink = document.querySelector("#root > div > div:nth-child(4) > div > div.bg-grey-card > div > div > div > div > div > div > ul:nth-child(2) > li:nth-child(4) > a");
      if (additionalLink) {
        additionalLink.href = data.data.newUrl;
      }
    }

    return data;
  },
};

const cancelApiObject = defineCancelApiObject(ConfigurationAPI);

export default ConfigurationAPI;
