import React, { useCallback, useEffect, useRef, useState } from "react";
import "../../global.css";

function PopupTextField(props) {
  const inRef = useRef(null);
  const [valid, setValid] = useState(true);
  const [inputValue, setInputValue] = useState(props.defaultValue || "");
  const isFile = props.fieldType === "file";

  useEffect(() => {
    setInputValue(props.defaultValue || "");
  }, [props.defaultValue]);

  const blur = (e) => {
    if (props?.onBlur) props.onBlur(e);
    isValid();
  };

  const isValid = useCallback(() => {
    if (props?.regex || props.error) {
      const reg = new RegExp(props?.regex ? props.regex : "[a-zA-Z0-9]");

      if (reg.test(inRef.current.value)) setValid(true);
      else setValid(false);
    }
  }, [props.error, props.regex]);

  useEffect(() => {
    if (props?.straightErr !== undefined) {
      setValid(!props.straightErr);
      return;
    }

    if (props.error) isValid();
  }, [isValid, props?.error, props?.straightErr]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    props.onChange(e);
  };

  const removeFileMod = (e) => {
    document.getElementById(e.target.id.split("x-")[1]).value = "";
    const x = { target: document.getElementById(e.target.id.split("x-")[1]) };

    props.onChange(x);
  };

  // Funzione per prevenire lo scrolling nel campo di input number e mantenere il valore >= 0
  const handleWheel = (e) => {
    if (props.fieldType === "number") {
      e.preventDefault();
      if (e.target.value < 0) {
        e.target.value = 0;
        props.onChange({ target: { id: e.target.id, value: 0 } });
      }
      inRef.current.blur();
    }
  };

  // Aggiungi o rimuovi il listener dell'evento wheel non passivo
  useEffect(() => {
    const input = inRef.current;
    if (props.fieldType === "number" && input) {
      input.addEventListener("wheel", handleWheel, { passive: false });

      return () => {
        input.removeEventListener("wheel", handleWheel);
      };
    }
  }, [props.fieldType, props.onChange]);

  return (
    <div className="form-group cmp-input top-move">
      <label
        className={`cmp-input__label ${inputValue ? "active" : ""} ${props.disabled ? "disabled" : ""} ${
          (props.fieldType === "date" || props.fieldType === "file") && "up-lbl"
        }`}
        htmlFor={props.id}
      >
        {props.fieldTitle}
      </label>
      {isFile && props?.defaultValue !== "" ? (
        <>
          <div style={{ padding: "50px 0 0 0", borderBottom: "solid 2px " }}>
            <label
              htmlFor={props.id}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <p className="change-file-span">
                  <span
                    style={{
                      borderRadius: ".3rem",
                      color: "var(--primary)",
                      fontSize: "14px",
                      padding: "0 .2rem 0 .2rem",
                      marginRight: ".5rem",
                      fontWeight: "bold",
                    }}
                  >
                    Modifica
                  </span>

                  {inRef?.current?.files[0]?.name.substring(0, 33) ??
                    props?.defaultValue.substring(0, 33)}
                </p>
              </div>
              <input
                style={{ fontWeight: "600", width: "100%" }}
                onBlur={blur}
                onChange={handleInputChange}
                type={props.fieldType}
                ref={inRef}
                className={`form-control ${props.disabled ? "disabled" : ""}  ${valid ? "" : "in-err"} ${
                  isFile && props?.defaultValue !== "" ? "transparent" : ""
                } toCheck`}
                id={props.id}
                name="vehicle-use"
                required
                data-dashlane-rid="f6746310e853864c"
                data-form-type="other"
                itr={props.itr}
                min={props.fieldType === "number" ? 0 : undefined}
              />
            </label>
          </div>
          <input
            style={{
              marginTop: "-80px",
              background: "transparent",
              border: "solid 1px red",
              borderRadius: "5px",
              fontSize: "11px",
              color: "red",
              float: "right",
              width: "20px",
              height: "20px",
              fontWeight: "bold",
            }}
            type="button"
            value="X"
            id={`x-${props.id}`}
            onClick={removeFileMod}
          />
        </>
      ) : (
        <input
          style={{
            fontWeight: "600",
            border: "none",
            borderBottom: "solid 1.5px grey",
            boxShadow: "none",
            padding: "5px",
          }}
          onBlur={blur}
          onChange={handleInputChange}
          type={props.fieldType}
          ref={inRef}
          className={`${props.disabled ? "disabled" : ""}  ${valid ? "" : "in-err"} full-btn-file`}
          id={props.id}
          name="vehicle-use"
          required
          data-dashlane-rid="f6746310e853864c"
          data-form-type="other"
          value={!isFile ? inputValue : ""}
          itr={props.itr}
          maxLength={props?.max}
          max={props?.min}
          onWheel={handleWheel}
          min={props.fieldType === "number" ? 0 : undefined}
        />
      )}
      <div className="d-flex">
        <span className="form-text cmp-input__text">
          {props.description
            ? props.description
            : "Inserire l’informazione per proseguire con la richiesta"}
        </span>
      </div>
    </div>
  );
}

export default PopupTextField;
