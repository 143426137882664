import React, { useState } from "react";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import FormBoxField from "../../UI-component/ui-el/FormBoxField";
import { titleExtractor, uniqueIdBuilder } from "../../util/Dynamics";
import NextBtnForm from "../../UI-component/NexBtnForm";
import { useDispatch, useSelector } from "react-redux";
import SuccessfullPopup from "../../UI-component/SuccessfullPopup";
import stepSlice from "../../context/StepsContext";

const ATTACHMENT_FIELDS = Array.from({ length: 10 }, (el, index) => `immagine${index + 1}`);

export default function SegnalazioneRiepilogo(props) {
  const dispatcher = useDispatch();

  const data = useSelector(state => state.stepSlice.data.autore_della_segnalazione);
  const disserviceCtx = useSelector(state => state.stepSlice.data.disservizio);

  const [testData, setTestData] = useState(data);
  const [loading, setLoading] = useState(false);

  const update = (data) => {
    setTestData(data.data);
  }

  const onNext = () => {
    // dispatcher(stepSlice.actions
    //   .dynamicSave({id: "Autore della segnalazione",data: testData}));

    // dispatcher(stepSlice.actions
    //   .dynamicSave({id: "disservizio", data: disserviceCtx})); 

    const data = {
      disservizio: { ...disserviceCtx },
      autore_della_segnalazione: testData
    }

    props.send(data);
  }

  const save = async () => {
    const data = {
      autore_della_segnalazione: testData?.length > 0 ? [{ ...testData[0], selected: true }] : [],
      disservizio: disserviceCtx,
    }

    setLoading(true);

    await props.save(data);

    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Autore della segnalazione", data: testData }));

    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Disservizio", data: disserviceCtx }));

    setLoading(false);
  }

  const renderField = (text, value, index = 0) => <FormBoxField title={text} value={value || "Non Compilato"} />

  const renderAttachments = () => ATTACHMENT_FIELDS.map((field, index) => renderField(`Allegato ${index + 1}`, disserviceCtx[field], index))

  return (
    <div className="row">
      <div className="col-12 col-lg-8 offset-lg-2">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">
            {
              testData.map((itm, idx) => {
                if (itm?.selected) {
                  return (
                    <React.Fragment key={uniqueIdBuilder()}>
                      <h2>Dati generali</h2>
                      <br></br>
                      <SummaryCard
                        taxID={itm?.anagrafica.codice_fiscale}
                        mod={true}
                        cardTitle={`${itm?.anagrafica?.nome} ${itm?.anagrafica.cognome}`}
                        type="Autore della segnalazione"
                        service={props.info.code}
                        registryData={itm}
                        update={update}
                      >
                        {
                          Object.keys(itm).map((key, idx) => {
                            return Object.keys(itm[key]).map(iKey => {
                              if (key !== "selected") {
                                return <FormBoxField
                                  title={iKey.includes("fiscale") ? "Codice Fiscale" : titleExtractor(iKey)}
                                  value={itm[key][iKey] ? itm[key][iKey] : "Non Compilato"}
                                  key={uniqueIdBuilder()} />
                              }
                              return <></>
                            })
                          })
                        }
                      </SummaryCard>
                    </React.Fragment>
                  )
                }
              })
            }

            <h2>Segnalazione</h2>
            <br></br>
            <SummaryCard
              mod={false}
              cardTitle=""
              type="Disservizio">
              {renderField("Indirizzo", disserviceCtx?.cerca_un_luogo)}
              {renderField("Tipo di disservizio", disserviceCtx?.tipo_di_disservizio)}
              {renderField("Titolo", disserviceCtx?.titolo)}
              {renderField("Dettagli", disserviceCtx?.dettagli)}
              {renderAttachments()}
            </SummaryCard>
          </div>
        </div>
        <NextBtnForm
          saveRequest={save}
          send={onNext}
          back={props.back}
          last={true}
          noSave={false} />

        {props.saved && <SuccessfullPopup />}
        {props.fail && <SuccessfullPopup isError={true} />}
      </div>
    </div>
  );
}




